import React, { useEffect, useState, useCallback } from "react";
import { FooterMobile } from "../../../mobilecomponents/FooterMobileMain";
import { NavbarMobile } from "../../../mobilecomponents/NavBarMobileMain";
import "./style.css";

export const NewsM = () => {
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(1);

  const handleGetStartedClick = useCallback(() => {
    window.open("https://xionpay.app", "_blank");
  }, []);

  const loadMoreArticles = () => {
    const newArticles = [
      {
        id: 1,
        title:
          "Xion Global — July 2023 Update — A Journey Towards Global Adoption for Web3 Payments",
        author: "Xion Global",
        date: "July 31, 2023",
        image: "/img/articlecover1.png",
        url: "/xion-global-july-2023-update-web3-payments-adoption",
      },
      {
        id: 2,
        title:
          "South Africans can now Scan to Pay with Crypto — Powered by Xion Global & Ukheshe",
        author: "Scan to Pay x Xion Global",
        date: "September 28, 2023",
        image: "/img/articlecover5.png",
        url: "/scan-to-pay-crypto-xion-global-ukheshe",
      },
      {
        id: 3,
        title:
          "MetaMask and Xion Global: Crafting the Future of 1-Click Web3 Payments in South Africa",
        author: "Metamask x Xion Global",
        date: "November 9, 2023",
        image: "/img/articlecover2.png",
        url: "/metamask-xion-global-1-click-web3-payments",
      },
      {
        id: 4,
        title:
          "Xion Global bridges the gap between blockchain and traditional financial systems with G+D’s Filia solution",
        author: "Xion Global x G+D",
        date: "November 23, 2023",
        image: "/img/articlecover4.png",
        url: "/xion-global-gd-filia-blockchain-traditional-finance",
      },
      {
        id: 5,
        title: "2023 — A Year of Triumphs and Transformation for Xion Global",
        author: "Xion Global",
        date: "December 29, 2023",
        image: "/img/articlecover3.png",
        url: "/xion-global-2023-achievements-web3-payments",
      },
      {
        id: 6,
        title:
          "Web3 Payments: Revolutionizing Financial Services with Blockchain Technology — Xion Global",
        author: "Xion Global",
        date: "June 24, 2024",
        image: "/img/articlecover7.png",
        url: "/web3-payments-blockchain-financial-services-xion-global",
      },
    ];
    setArticles((prevArticles) => [...newArticles.reverse(), ...prevArticles]);
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    loadMoreArticles();
  }, []);

  return (
    <div className="NewsM">
      <div className="BG-elements">
        <div className="overlap-4">
          <div className="hero">
            <div className="overlap-5">
              <img
                className="rectangle-9"
                alt="Rectangle"
                src="/img/mobilehero.webp"
              />
            </div>
          </div>
          <img className="noize" alt="Noize" src="/img/noize-1.png" />
        </div>
      </div>
      <img
        className="nav-spacer"
        alt="Nav spacer"
        src="/img/nav-spacer-1.png"
      />
      <NavbarMobile onGetStartedClick={handleGetStartedClick} />
      <div className="main">
        <div className="text-block">
          <div className="HT">
            {/* <div className="a-SIMPLE">PAY WITH CRYPTO</div> */}
            <div className="FREE-SIMPLE"> XION NEWS</div>
            <div className="payment-solution-you"></div>
          </div>
          <p className="p">
            Discover the premier source for all Web3 payments news and updates.
            <br></br>
            <br></br>
            Stay informed about groundbreaking partnerships, cutting-edge
            innovations, and the latest insights in Web3 payments technology.
            Our channel provides comprehensive coverage and expert analysis to
            keep you at the forefront of the evolving digital payments
            landscape.
            <br></br>
            <br></br>
            Whether you're a seasoned professional or new to the industry, our
            content is designed to enhance your understanding and keep you
            updated with the most relevant information.
          </p>
        </div>
      </div>
      <div className="featured-articles">
        <div className="article-large-1">
          <a href="/tether-xion-global-blockchain-education-south-africa">
            <img
              className="img-article-1"
              src="/articlecover6.png"
              alt="Xion Global x Tether"
            />
            <div className="article-info">
              <h2>
                Tether and Xion Global Announce Strategic Collaboration to Drive
                Blockchain Education in South Africa
              </h2>
              <p>By Xion Global & Tether - August 05, 2024</p>
            </div>
          </a>
        </div>
        <div className="article-large-2">
          <a href="/xion-global-axelar-squid-cross-chain-payments">
            <img
              className="img-article-2"
              src="/img/xion-axelar-squid.png"
              alt="Axelar Squid Xion Global"
            />
            <div className="article-info">
              <h2>
                Xion Global Forges Strategic Integration with Axelar and Squid
                to Unify Cross-Chain Payments
              </h2>
              <p>By Xion x Axelar x Squid - July 31, 2023</p>
            </div>
          </a>
        </div>
      </div>

      <div className="articles-grid">
        {articles.map((article) => (
          <div key={article.id} className="article-small">
            <a href={article.url}>
              <img src={article.image} alt={article.title} />
              <div className="article-info">
                <h3>{article.title}</h3>
                <p>
                  By {article.author} - {article.date}
                </p>
              </div>
            </a>
          </div>
        ))}
      </div>
      {/* <button onClick={loadMoreArticles} className="load-more">
        Load More
      </button> */}

      <div className="section-2">
        <div className="TEXT-wrapper">
          <div className="TEXT-2">
            <p className="div-4">
              <span className="span">X</span>
              <span className="span">ion Docs</span>
            </p>
            <p className="div-5">
              <span className="text-wrapper-19">
                Explore guides and integration examples in Xion{" "}
              </span>
              <span
                className="text-wrapper-20"
                onClick={() =>
                  handleOpenURL("https://www.apimatic.io/api-docs/xionglobal")
                }
              >
                documentation
              </span>
              <span className="text-wrapper-19">.</span>
            </p>
          </div>
        </div>
        <div className="frame-7">
          <div className="TEXT-3">
            <p className="div-4">
              <span className="span">Technical</span>
              <span className="span">&nbsp;</span>
              <span className="span">and</span>
              <span className="span">&nbsp;</span>
              <span className="span">Support</span>
            </p>
            <p className="div-5">
              <span className="text-wrapper-19">
                We’re here to help! If you have technical issues,{" "}
              </span>
              <span
                className="text-wrapper-20"
                onClick={() =>
                  handleOpenURL("https://intercom.help/xionpay/en/")
                }
              >
                help guide
              </span>
              <span className="text-wrapper-19">.</span>
            </p>
          </div>
        </div>
        <img className="line-2" alt="Line" src="/img/line-23-mobile.svg" />
      </div>
      <FooterMobile onGetStartedClick={handleGetStartedClick} />
    </div>
  );
};
